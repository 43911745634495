import "../App.css";
import React, { useEffect } from "react";

import Header from "../components/Header";
import Carousel from "../components/Carousel";
import RaveSection from "../components/RaveSection";
import Card from "../components/Card";
import Info from "../components/Info";
import Footer from "../components/Footer";

export default function Home() {
  useEffect(() => {
    document.title = "DNMGD | HOME";
  }, []);

  return (
    <main className="h-full w-full">
      <Header />
      <Info />
      <Card limit={3} />
      <Footer />
    </main>
  );
}
