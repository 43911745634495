import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Checkout from "../components/CheckOut";

export default function About() {
  useEffect(() => {
    document.title = "Checkout";
  }, []);
  return (
    <>
      <Header />
      <Checkout />
      <Footer />
    </>
  );
}
