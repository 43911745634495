import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";

import cart from "../imgs/cart.svg";
import CartModal from "./CartModal";
import { BASE_URL } from "../config/constants";
import { MyContext } from "../context/credentialContext";

export default function Cart() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cartcount, setcartcount] = useState(0);
  const { contextValue } = useContext(MyContext);

  const getItems = () => {
    if (Cookies.get("userId") == undefined) {
      setcartcount(0);
    } else {
      let userId = Cookies.get("userId");
      let url = `${BASE_URL}/cart/${userId}`;

      axios
        .get(url)
        .then((response) => {
          let data = response.data;

          setcartcount(data.data.length);
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    getItems();
  }, [contextValue]);

  return (
    <>
      <div className="flex items-center bg-cream py-2">
        <div
          className="flex justify-end items-center relative w-full mr-2 cursor-pointer"
          onClick={() => setIsModalOpen(true)}
        >
          <img className="w-6 md:w-6" src={cart} alt="cartpng" />
          {/* Show cart item count */}
          <span
            className="absolute top-2/3 bg-red-500 text-white text-sm
        w-4 h-4 rounded-full flex justify-center items-center"
          >
            {cartcount}
          </span>
        </div>
      </div>

      <CartModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
}
