import React from "react";
import Header from "../components/Header";
import Cart from "../components/Cart";
import ProductDetails from "../components/ProductDetails";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";

export default function ProductPage() {
  const location = useLocation();
  const { item } = location.state;

  return (
    <div>
      <Cart />
      <Header />
      <ProductDetails details={item} />
      <Footer />
    </div>
  );
}
