import React, { useEffect } from "react";
import Header from "../components/Header";
import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";

export default function About() {
  useEffect(() => {
    document.title = "About Us";
  }, []);
  return (
    <>
      <Header />
      <AboutUs />
      <Footer />
    </>
  );
}
