import React from "react";
import arrow from "../svg/arrow.svg";
export default function Footer() {
  return (
    <footer className=" bg-white text-black font-Neuehaasdisplay font-light mt-20 ">
      <div className="flex flex-col md:flex-row justify-between  mx-5 py-10 ">
        <p className="md:w-1/2 mt-5 text-sm md:text-base my-5 tracking-[1px]">
          DNMGD is the leading fashion movement in the country, delivering
          iconic styles and pushing the boundaries of Nigeria's bold fashion
          culture.
        </p>
        <div className="items-center flex">
          <ul className="underline uppercase md:text-base text-sm tracking-[1px]">
            <a href="https://instagram.com/dnmgdx" className="flex">
              <img
                src={arrow}
                alt="arrow up"
                className="stroke-black w-4 mr-1"
              />
              Instagram
            </a>
            <a href="mailto:stunnerrave@gmail.com" className="flex">
              ADMIN@DNMGD.COM
            </a>
          </ul>
        </div>
      </div>
      <div className="bg-black h-[1px] mb-3 mx-5"></div>
      <p className="mx-5 md:py-10 py-5 text-xs md:text-base">
        © 2024 by DNMGD. Proudly created by{" "}
        <a
          href="mailto:bullshtstudios@gmail.com"
          className="underline md:text-base text-xs"
        >
          Bullshit Studios
        </a>
      </p>
    </footer>
  );
}
