import React from "react";

export default function CartItem({ item, onChange, quantity, remove }) {
  return (
    <div className="flex my-5">
      <img
        src={`https://admin.stunnerrave.com/images/product_images/medium/${item.main_image}`}
        alt={item.product_name}
        className="w-24 h-24 object-cover mr-4"
      />
      <div className="flex-1 text-black">
        <h3 className="font-bold text-lg">{item.product_name}</h3>
        <p className="text-sm text-gray-600">
          NGN {item.price.toLocaleString()}
        </p>

        {/* Quantity Selector */}
        <div className="mt-3 flex items-center space-x-2">
          <p className="text-sm text-black">Size: {item.size}</p>
          <input
            type="number"
            value={quantity}
            min="1"
            onChange={onChange}
            className="border p-1 w-16 text-center"
          />
        </div>

        {/* Remove Option */}
        <button className="text-red-500 mt-2" onClick={remove}>
          Remove
        </button>
      </div>
    </div>
  );
}
