import React, { createContext, useState } from "react";

export const MyContext = createContext();

// 2. Create the provider component
export const MyContextProvider = ({ children }) => {
  const [contextValue, setContextValue] = useState(1);

  return (
    <MyContext.Provider value={{ contextValue, setContextValue }}>
      {children}
    </MyContext.Provider>
  );
};
