import React from "react";
import { useState } from "react";
import st from "../imgs/st.jpg";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .send(
  //       "YOUR_SERVICE_ID", // Replace with your EmailJS service ID
  //       "YOUR_TEMPLATE_ID", // Replace with your EmailJS template ID
  //       formData,
  //       "YOUR_USER_ID" // Replace with your EmailJS user ID
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         alert("Email sent successfully!");
  //       },
  //       (error) => {
  //         console.log(error.text);
  //         alert("An error occurred, please try again.");
  //       }
  //     );
  // };
  return (
    <div className="flex flex-col md:flex-row space-x-5">
      <img src={st} alt="" className="md:w-1/2 md:h-1/2 md:mt-10" />
      <div className="bg-black text-white  flex flex-col ">
        <h1 className="md:text-6xl mt-7 mb-7 font-light text-3xl md:mt-[200px] font-Neuehaasdisplay">
          Talk To Us
        </h1>
        <p className="md:text-lg text-sm mb-10 font-Neuehaasdisplay font-light font-[15px]">
          Connect with us. Drop us a mail to{" "}
          <span className="font-bold">admin@dnmgd.com</span>, engage with us on
          <span className="font-bold">@dnmgdx</span>, or simply fill out the
          form below. Your questions, feedback, or just a friendly hello are
          always welcome.
        </p>

        <form className="mx-2 font-Neuehaasdisplay font-light tracking-[1px]">
          <div className="mb-6">
            <label className="block text-sm font-light mb-1" htmlFor="name">
              Name
            </label>
            <input
              className="w-full px-4 py-2 border border-gray-300 text-black"
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-light mb-1" htmlFor="email">
              Email Address
            </label>
            <input
              className="w-full px-4 py-2 border border-gray-300 text-black"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-light mb-1" htmlFor="message">
              Message
            </label>
            <textarea
              className="w-full px-4 py-2 border border-gray-300 text-black"
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <button
            className="bg-cream font-Neuehaasdisplay text-black font-bold py-2 px-4 w-full hover:bg-gray-200"
            type="submit"
          >
            Send Email
          </button>
        </form>
      </div>
    </div>
  );
}
