import React from "react";
import Header from "../components/Header";
import Card from "../components/Card";
import Footer from "../components/Footer";
import Cart from "../components/Cart";

export default function Merch() {
  return (
    <>
      <Cart />
      <Header />
      <Card />
      <Footer />
    </>
  );
}
