import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { BASE_URL } from "../config/constants";
import Marque from "./Marque";

export default function Card({ limit }) {
  const location = useLocation();
  const isMerchPage = location.pathname.includes("/merch");
  const [merch, setMerch] = useState([]);

  useEffect(() => {
    getMerch();
  }, []);

  const getMerch = () => {
    let url = `${BASE_URL}/products`;
    axios
      .get(url)
      .then((response) => {
        let data = response.data;

        if (limit) {
          setMerch(data.data.splice(0, limit));
        } else {
          setMerch(data.data);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {!isMerchPage && <Marque child={"SHOP"} number={15} />}

      <div className="mt-5 mx-5">
        <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mt-5 text-black text-center">
          {merch.map((item) => (
            <Link
              key={item.id}
              to={`/merch/${item.product_name}`}
              state={{ item }}
              className="p-4 border border-border-subtle"
            >
              <img
                src={`https://admin.stunnerrave.com/images/product_images/medium/${item.main_image}`}
                alt={item.product_name}
              />
              <p className="text-cream font-Neuehaasdisplay font-bold tracking-wide text-left">
                {item.product_name}
              </p>
              <p className="text-white font-Neuehaasdisplay font-light text-left">
                ₦{item.price.toLocaleString()}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
