import React, { useEffect } from "react";
import Ticket from "../components/Tickets.jsx";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function TicketPage() {
  useEffect(() => {
    document.title = "Tickets";
  }, []);
  return (
    <div className="h-full w-full">
      <Header />
      <Ticket />
      <Footer />
    </div>
  );
}
